/**
 * @title App
 * @description Application entry point
  */


// Jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;
// Barba - pages transition
import barba from "@barba/core";
// import barbaCss from "@barba/css";

import Flickity from "flickity";
import "flickity/css/flickity.css";
require("flickity-fade");
require("flickity-bg-lazyload");
require("flickity-as-nav-for");

import lightGallery from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

var jQueryBridget = require("jquery-bridget");
// Infinite scroll
var InfiniteScroll = require("infinite-scroll");
jQueryBridget( 'infiniteScroll', InfiniteScroll, $ );

// Google maps api
import { Loader } from "@googlemaps/js-api-loader";

// Components
// import spinner from "./components/spinner";

require("./components/thom_advanced_search");

var Packery = require("packery");
$.bridget("packery", Packery, $);

var imagesLoaded = require("imagesloaded");

require("jquery.resizeend");

// Thom Form
require("./components/thom_form");

// Thom Map
require("./components/thom_map");
require("./components/thom_mapping");

// Modules
import navbar from "../modules/navbar/navbar";
import searchOverlay from "../modules/navbar/search-overlay";
import thomDropdown from "../modules/dropdown/dropdown";

// wishlist
require("./components/THOM/THOM.global");
require("./components/THOM/THOM.wishlist");
require("./components/wishlist");

import "@chenfengyuan/datepicker/dist/datepicker.min.js";
import "@chenfengyuan/datepicker/i18n/datepicker.it-IT";
import "@chenfengyuan/datepicker/i18n/datepicker.de-DE";
import "@chenfengyuan/datepicker/i18n/datepicker.fr-FR";
import "@chenfengyuan/datepicker/i18n/datepicker.pl-PL";
import "@chenfengyuan/datepicker/i18n/datepicker.es-ES";
import "@chenfengyuan/datepicker/i18n/datepicker.pt-BR";
import "@chenfengyuan/datepicker/i18n/datepicker.ru-RU";
import "@chenfengyuan/datepicker/i18n/datepicker.zh-CN";
import "@chenfengyuan/datepicker/i18n/datepicker.ar-IQ";
import "@chenfengyuan/datepicker/dist/datepicker.min.css";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});
ScrollTrigger.config({ ignoreMobileResize: true });

import scrollAnimation from './components/scrollAnimation';
import LocomotiveScroll from 'locomotive-scroll';

import Player from '@vimeo/player';

const locoScroll = new LocomotiveScroll({
  el: document.querySelector(".smooth-scroll"),
  smooth: true,
  // lerp: 0.05,
  // initPosition: { x: 0, y: 0 },
  tablet: { smooth: false },
  smartphone: { smooth: false }
});

locoScroll.on("scroll", ScrollTrigger.update);

ScrollTrigger.scrollerProxy(".smooth-scroll", {
  scrollTop(value) {
    return arguments.length
      ? locoScroll.scrollTo(value, 0, 0)
      : locoScroll.scroll.instance.scroll.y;
  },
  getBoundingClientRect() {
    return {
      top: 0,
      left: 0,
      width: window.innerWidth,
      height: window.innerHeight
    };
  },
  pinType: document.querySelector(".smooth-scroll").style.transform ? "transform" : "fixed"
});

ScrollTrigger.addEventListener("refresh", () => {
  locoScroll.update()
});
ScrollTrigger.defaults({ scroller: ".smooth-scroll" });


// exist element
$.fn.exists = function () {
  return this.length !== 0;
};

// Modal default options
$.modal.defaults = {
  clickClose: true,
  escapeClose: true,
  fadeDuration: 250,
  fadeDelay: 1.1,
  showClose: false,
  spinnerHtml: '<div id="spinner-temp" class="default"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></div>',
  blockerClass: "jquery-modal"
}

function setCookie (name, value, days) {
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    var expires = '; expires=' + date.toGMTString()
  } else var expires = ''
  document.cookie = name + '=' + value + expires + '; path=/'
}

function getCookie (name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function deleteCookie (name) {
  setCookie(name, '', -1)
}

function detectTouchscreen() {
  var result = false;
  if (window.PointerEvent && ('maxTouchPoints' in navigator)) {
    // if Pointer Events are supported, just check maxTouchPoints
    if (navigator.maxTouchPoints > 0) {
      result = true;
    }
  } else {
    // no Pointer Events...
    if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
      // check for any-pointer:coarse which mostly means touchscreen
      result = true;
    } else if (window.TouchEvent || ('ontouchstart' in window)) {
      // last resort - check for exposed touch events API / event handler
      result = true;
    }
  }
  return result;
}

// Form Response Action
function MyThomFormResponseAction(title, text) {
  var mytitle;
  if (title !== undefined && title !== null && title !== "") {
    mytitle = "<div class='h2'>" + title + "</div>";
  } else {
    mytitle = "";
  }

  var myHtml =
    '<div class="modal modal-callback">' +
      '<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
      mytitle +
      "<div class='paragraph'>" + text + "</div>"; +
    '</div>';

  $(myHtml).appendTo("body").modal();
}

// Colors modal
function ColorsModal(link) {
  var modaltitle = $(link).find(".title").text();
  var modalimage = $(link).data("image");

  var myHtml =
    '<div class="modal modal-colors">' +
      '<div class="modal-colors-title">' +
        '<div class="title-font">' +
        modaltitle +
        '</div>' +
        '<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
      '</div>' +
      '<div class="modal-colors-content">' +
        '<img data-src="' +
        modalimage +
        '" alt="" class="lazy"></img>' +
      '</div>' +
    '</div>';

  $(myHtml).appendTo("body").modal();

  $(document).on($.modal.OPEN, function (event, modal) {
    myLazyLoad.update();
  });

  $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
    $(".modal-colors").remove();
  });
}

// Carousel instances
let flktyHomeFocus,
    flktyProjectsPreviewBk = null;

// Carousel Parallax effect
function CarouselParallax(carousel, instance) {
  let cell = $(carousel).find(".carousel-cell").length;
  let imgs = $(carousel).find(".carousel-cell-image");
  // get transform property
  let docStyle = document.documentElement.style;
  let transformProp =
    typeof docStyle.transform === "string" ? "transform" : "WebkitTransform";

  instance.on("scroll", function () {
    instance.slides.forEach(function (slide, j) {
      let img = imgs[j];
      let x = 0;
      if (0 === j && cell > 2) {
        x =
          Math.abs(instance.x) > instance.slidesWidth
            ? instance.slidesWidth +
              instance.x +
              instance.slides[instance.slides.length - 1].outerWidth +
              slide.target
            : slide.target + instance.x;
      } else if (j === instance.slides.length - 1) {
        x =
          Math.abs(instance.x) + instance.slides[j].outerWidth <
          instance.slidesWidth
            ? slide.target -
              instance.slidesWidth +
              instance.x -
              instance.slides[j].outerWidth
            : slide.target + instance.x;
      } else {
        x = slide.target + instance.x;
      }
      img.style[transformProp] = "translateX(" + x * (-1 / 3) + "px)";
    });
  });
}

// Carousel Progress bar
function CarouselProgressbar(carousel, instance) {
  let cell = $(carousel).find(".carousel-cell").length;
  if (cell > 1) {
    $(carousel).find(".carousel-progress").show();
    let caption = $(carousel).find(".carousel-cell-caption");
    let duration = 5000;
    let interval = 10;
    let progressBar = $(carousel).find(".progress");
    let isPaused = false;

    caption.on("mouseenter", function () {
      isPaused = true;
    });

    caption.on("mouseleave", function () {
      isPaused = false;
    });

    // Main function
    let percentTime;
    let step;
    let tick;

    function startProgressbar() {
      resetProgressbar();
      percentTime = 0;
      isPaused = false;
      tick = window.setInterval(increase, interval);
    }

    function increase() {
      if (!isPaused) {
        step = duration / interval;
        percentTime += 100 / step;
        progressBar.width(percentTime + "%");
        if (percentTime >= 100) {
          if (instance.selectedIndex === instance.slides.length - 1) {
            instance.select(0);
          } else {
            instance.next();
          }
          startProgressbar();
        }
      }
    }

    function resetProgressbar() {
      progressBar.width(0 + "%");
      clearTimeout(tick);
    }

    startProgressbar();

    instance.on("change", function (index) {
      startProgressbar();
    });
  } else {
    $(carousel).find(".carousel-progress").hide();
  }
}

// share buttons
function shareButtons() {
  // Section Intro Fade Up
  if ($(".share").exists()) {
    
    var g = 0;
    let shareButtonsInit = () => {
      if ($("html").hasClass("touchevents")) {
        $(".share-btn.messenger-desk").hide();
        $(".share-btn.messenger-mob").show(0, function () {
          $(this).css("display", "block");
        });
      } else {
        $(".share-btn.messenger-mob").hide();
        $(".share-btn.messenger-desk").show(0, function () {
          $(this).css("display", "block");
        });
      }
      
      if (g !== 1) {
        $(".copy-link").click(function (e) {
          e.preventDefault();
          var link = $(this).attr("href");
          var $temp = $("<input>");
          $("body").append($temp);
          $temp.val(link).select();
          // $temp.setSelectionRange(0, 99999);
          document.execCommand("copy");
          $temp.remove();

          $($(this).data("modal")).modal();
          return false;
        });
      }

      // if (window.innerWidth <= 990) {
        $(".share").click(function () {
          $(this).toggleClass("is-active");
        });
      // }
    }

    $(window).on("resizeend", 250, function () {
      g = 1;
      shareButtonsInit();
    });

    shareButtonsInit();
  } 
}

// TOM map Marker Icon
function myThomMapMarkerIcon(item, isDett) {
  var category = "default";

  // if ($(item).is("[cat]")) {
  //   category = $(item).attr("cat");
  // }

  if (params.type != undefined) {
    category = params.type
  }

  var markerIcon = {
    default: {
      icon: "/img/marker.png",
      height: 24,
      width: 18,
    },
    stores: {
      icon: "/img/marker-dealers.png",
      height: 59,
      width: 50,
    },
  };

  return markerIcon[category]
}

let elsToClear = '';
function clearScene(timeline) {

  var targets = timeline.getChildren();
  for (var i=0; i<targets.length; i++) {
    if (targets[i].target != null) {
      gsap.set(targets[i].target.selector, {clearProps:"all"});
    }
  }
  if(elsToClear) {
    gsap.set(elsToClear, {clearProps:"all"});
  }
  if(timeline.scrollTrigger) {
    timeline.scrollTrigger.kill(true);
  }
  timeline.pause(0).kill(true);
}

var finishesLoaded = 0;
function loadAllFinishes(url) {
  if(!finishesLoaded){
    $("#spinner").addClass("trans");
    // spinner().show();
    var postdata = { selectedFilters: null, itemsXPage: 0, orderByPosition: false }
    postdata = JSON.stringify(postdata);
    $.ajax({
      type: 'POST',
      url: url,
      contentType: 'application/json; charset=utf-8',
      data: postdata,
      dataType: 'html',
      success: function (data) {  
        $('.product-finishes .thom-search--results').html(data);  
        var items = $('.product-finishes .thom-search--results').find('.finishes-list--item');
        
        // console.log(items.length)
        
        for(var i = 0; i < items.length; i++){
          var item = items[i];

          // console.log(i)

          if ($(item).find(".lazy").length > 0) {
            myLazyLoad.update();
          }

          if((i + 1) == (items.length)){
            // spinner().hide();
            // console.log('all finieshes are loaded');
            setTimeout(function(){
              locoScroll.update();
              $("#spinner").removeClass("trans");
            }, 150);
          }
        }

        filterFinishes(url);
        finishesLoaded = 1;
      }
    })
  }
}

function filterFinishes(url){
  ThomFinishesSearch = null;
  ThomFinishesSearch = $('.product-finishes .thom-search').thomsearch({
    filterClass: '.product-finishes .thom-search--filter-btn',
    filtersContent: '.product-finishes .thom-search--filter-block',
    itemsPerPage: 0,
    resultsCallback: function (data) {
      
      $('.product-finishes .thom-search--results').html(data);  
      var items = $('.product-finishes .thom-search--results').find('.finishes-list--item');

      for(var i = 0; i < items.length; i++){
        var item = items[i];

        if ($(item).find(".lazy").length > 0) {
          myLazyLoad.update();
        }

        if((i + 1) == (items.length)){
          ThomFinishesSearch.thomsearch('loadingHide');
          locoScroll.update();
        }
      }
    },
    urlContent: url
  });
}

let myLazyLoad;
var ThomAdvancedSearch = null;
var ThomFinishesSearch = null;

let myGoogleMaps = null;

$(document).on($.modal.AFTER_CLOSE, function (event, modal) {
  $(".ajax-load.modal").remove();
});
  
// Analytics Modal update
$(document).on($.modal.OPEN, function (event, modal) {
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
  $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
});
$(document).on($.modal.CLOSE, function (event, modal) {
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
});

// Job form append
function jobControl(item) {
  
  if(item.hasClass('jobs--item')){
    
    var content = item.find('.jobs--item--content');
    var title = item.attr('data-job-title');
    var id = parseInt(item.attr("data-job-id"));

    $("#formJob").hide().appendTo(content).slideDown(100, function(){
      // Reset form
      $("#formJobOpportunities").resetForm();
      $("#formJobOpportunities .thom-form__error").hide(0);

      if ($("#formJobOpportunities").find('input[type=file]').exists()) {
        $("#formJobOpportunities .thom-form-selectfile__label span").html($("#formJobOpportunities").find('input[type=file]').attr('placeholder'))
      }      
      
      // Passo valori a campi nascosti
      $("#formJobOpportunities #TitoloLavoro").val(title);
      $("#formJobOpportunities #JobId").val(id);

      // Se id 0 mostro campi per Candidatura spontanea
      if(id == 0){
        $('#formJob').find('.only-for-free-application').show();
        $('#formJob').find('.only-for-free-application-el').attr('data-val-required', true);
      } else {
        $('#formJob').find('.only-for-free-application').hide();
        $('#formJob').find('.only-for-free-application-el').attr('data-val-required', false);
      }

      // Scroll update e riposizionamento
      setTimeout(function(){
        locoScroll.scrollTo(item[0], {
          offset: - $('.navbar').height() * 2,
          callback: locoScroll.update(),
          duration: 250
        })
      }, 200);
    });
  }
}

// Start APP
function myapp(container) {
  //console.log('MY APP - START - ad ogni nuova pagina, dopo transizione BARBA');
  let myMapKey = 'AIzaSyCFWQ5W77T1Zpc1AL77MQpXhQfUpIeCQ_U';

  if (typeof _gmapkey !== 'undefined') {
    myMapKey = _gmapkey
  }

  let loader = new Loader({
    apiKey: myMapKey,
    version: "weekly",
    libraries: ["places", "geometry"]
  });

  navbar(locoScroll);

  // reset navigation set
  $("body")
    .removeClass("navbar--search-opened")
    .removeClass("navbar--opened");

  $(".navbar").removeClass("out-stage");

  $(".wrapper").removeAttr("style");
  $(".main").removeAttr("style");
  
  let packeryInstance = null;
  // myLazyLoad = null;
  
  myLazyLoad = new LazyLoad({
    container: container,
    unobserve_entered: true,
    elements_selector: ".lazy",
    callback_loaded: function(el){
      locoScroll.update();
    }
  });

  finishesLoaded = 0;

  // wishlist
  var thom_wishlist = new THOM.WISHLIST('gallotti_wishlist', 60, 'Id', false)

  if ($('.wishlist-list').exists()) {
    new list_wishlist(thom_wishlist).Init()
  } else {
    new icons_wishlist(thom_wishlist).Init()
  }

  ThomAdvancedSearch = null;
  ThomFinishesSearch = null;

  // search
  searchOverlay();

  // Dropdown
  thomDropdown();

  // Share
  shareButtons();

  // Home Carousel
  if ($(".carousel-home").exists()) {
    var firstLL = 0;

    var flktyHomeOptions = {
      autoPlay: 3500,
      cellSelector: ".carousel-home .carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: true,
      bgLazyLoad: true,
      lazyLoad: 2,
      fade: false,
      wrapAround: true,
      draggable: true,
      on: {
        lazyLoad: function (event, cellElement) {
          if($(cellElement).index() === 0 && firstLL === 0){
            firstLL = 1;
            $('.emotional-home-content').addClass('tween');
          }
        }
      }
    };

    if ( matchMedia('screen and (min-width: 768px)').matches ) {
      flktyHomeOptions.fade = true;
      flktyHomeOptions.wrapAround = false;
    }

    flktyHome = new Flickity(".carousel-home", flktyHomeOptions);
  }

  // Home Focus On
  if ($(".carousel-focuson").exists()) {
    flktyHomeFocus = new Flickity(".carousel-focuson", {
      autoPlay: 0,
      cellSelector: ".carousel-focuson .carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: false,
      bgLazyLoad: true,
      lazyLoad: 2,
      // fade: true,
      draggable: true
    });

    CarouselParallax(".carousel-focuson", flktyHomeFocus);

    // elements
    var $cellButtonGroup = $('.carousel-focuson--dots');
    var $cellButtons = $cellButtonGroup.find('.dot');

    // update selected cellButtons
    flktyHomeFocus.on( 'select', function() {
      $cellButtons.filter('.is-selected')
        .removeClass('is-selected');
      $cellButtons.eq(flktyHomeFocus.selectedIndex)
        .addClass('is-selected');

      $(".home-focus--over .label").addClass("hide");
      setTimeout(function () {
        $(".home-focus--over .label").text(
          flktyHomeFocus.selectedElements[0].attributes.title
            .textContent
        );
        $(".home-focus--over .label").removeClass(
          "hide"
        );
        
      }, 400);
    });

    // select cell on button click
    $cellButtonGroup.on( 'click', '.dot', function() {
      var index = $(this).index();
      flktyHomeFocus.select( index );
    });
  }
  
  // Product Dett
  // Carousel
  if ($(".carousel-product").exists()) {
    flktyProduct = new Flickity(".carousel-product", {
      autoPlay: 0,
      cellSelector: ".carousel-product .carousel-cell",
      pauseAutoPlayOnHover: true,
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: true,
      fade: true,
      draggable: true
    });

    // CarouselParallax(".carousel-product", flktyProduct);
  }

  // Product dett accordion
  if($('.product_dett .acc').exists()){
    $('.product_dett .acc').each(function(){
      var $this = $(this);
      $this.find('.acc--title').on('click', function(){
        $this.toggleClass('open');
        $this.find('.acc--content').stop().slideToggle();
      })
    })
  }

  // Product dett tabs
  if($('.product-tabs').exists()){
    var tabChange = function(n){
      $('.product-tabs .tabs--nav .item').removeClass('selected');
      $('.product-tabs .tabs--nav .item').eq(n).addClass('selected');
      
      $('.product-tabs .tabs--content .item').removeClass('selected');
      $('.product-tabs .tabs--content .item').eq(n).addClass('selected');

      $('.tabs--nav__dropdown .item').removeClass('selected');
      $('.tabs--nav__dropdown .item').eq(n).addClass('selected');
      $('.tabs--nav__dropdown .thom-dropdown--label-item').text($('.tabs--nav__dropdown .item').eq(n).text())

      setTimeout(function(){
        locoScroll.update();
      }, 100);
      
      if($('.product-tabs .tabs--nav .item').eq(n).hasClass('tab-finishes')){
        loadAllFinishes($('.product-tabs .tabs--nav .item').eq(n).data('url'));
      }
    }

    $('.product-tabs .tabs--nav .item').on('click', function(e){
      e.preventDefault();
      var n = $(this).index();
      tabChange(n);
    });

    $('.tabs--nav__dropdown .item').on('click', function(e){
      e.preventDefault();
      var n = $(this).index();
      tabChange(n);
    })
  }

  // projects preview carousel
  if ($(".projects-preview").exists()) {

    $(".projects-preview--carousel-bk").removeClass("is-hidden");
    flktyProjectsPreviewBk = new Flickity(".projects-preview--carousel-bk", {
      cellSelector: ".projects-preview--carousel-bk .carousel-cell",
      // asNavFor: '.projects-preview--carousel',
      draggable: false,
      pageDots: false,
      lazyLoad: 2,
      prevNextButtons: false,
      pageDots: false,
      on: {
        ready: function () {
          $(".projects-preview .overall .slide-n .tot").html(
            $(".projects-preview--carousel-bk .carousel-cell").length
          );
        },
      },
      // fade: true
    });
    CarouselParallax(".projects-preview--carousel-bk", flktyProjectsPreviewBk);

    // previous
    $(".projects-preview .overall .nav-btn-prev").on("click", function () {
      flktyProjectsPreviewBk.previous();
    });
    // next
    $(".projects-preview .overall .nav-btn-next").on("click", function () {
      flktyProjectsPreviewBk.next();
    });
    // on change
    flktyProjectsPreviewBk.on("change", function (index) {
      $(".projects-preview .overall .slide-n .current").html(index + 1);
    });
    // on select
    flktyProjectsPreviewBk.on("select", function () {
      $(".projects-preview .overall .overall-text-change").addClass("hide");
      setTimeout(function () {
        $(".projects-preview .overall .title").text(
          flktyProjectsPreviewBk.selectedElements[0].attributes.title
            .textContent
        );
        $(".projects-preview .overall .subtitle").text(
          flktyProjectsPreviewBk.selectedElements[0].attributes.subtitle
            .textContent
        );
        $(".projects-preview .overall .btn").attr(
          "href",
          flktyProjectsPreviewBk.selectedElements[0].attributes.link.textContent
        );
        $(".projects-preview .overall .overall-text-change").removeClass(
          "hide"
        );
      }, 350);
    });
  }

  // Project Map
  if ($(".project-dett--info__map").exists() && $(".project-dett--info__map #map").exists()) {
    loader.load().then(async (google) => {

      let myGoogleMapsProject = new google.maps.Map(container.querySelector("#map"), {
          center: { lat: params.latitude, lng: params.longitude },
          zoom: 8,
          maxZoom: 18,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        });

        var image = {
          url: "/img/marker-dealers.png",
          size: new google.maps.Size(59, 50),
        };
        var myMarker = new google.maps.Marker({
          position: { lat: params.latitude, lng: params.longitude },
          map: myGoogleMapsProject,
          icon: image,
        });
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(myMarker.getPosition());
        myGoogleMapsProject.fitBounds(bounds);
      })
      .catch((e) => {
        // do something
        console.log(e)
      });
  }

  // Gallery
  if ($('.gallery-list').exists()) {
    let packeryGalleryList = null;

    if($('.gallery-list .gallery-list--item').length > 2){
      var elem = container.querySelector('.gallery-list--inner');
      var items = elem.querySelector(".gallery-list--item:not(.hidden)");
      packeryGalleryList = new Packery( elem, {
        // options
        itemSelector: '.gallery-list--item',
        transitionDuration: 0,
        percentPosition: true
      }); 

      imagesLoaded( elem ).on( 'progress', function() {
        packeryGalleryList.layout();
        locoScroll.update();
      });

      var $this = $('.gallery-list');
      var $hiddenEl = $this.find('.hidden');
      var $moreBtn = $this.find('.show-more');
      var $lessBtn = $this.find('.show-less');

      $moreBtn.on('click', function(){
        $moreBtn.addClass('is-hidden');

        $hiddenEl.show();
        $hiddenEl.removeClass('out').addClass('active');

        packeryGalleryList.layout();
        locoScroll.update();
        $lessBtn.removeClass('is-hidden');
      });

      $lessBtn.on('click', function(){
        $lessBtn.addClass('is-hidden');
        
        $hiddenEl.removeClass('active').addClass('out');

        setTimeout(function(){
          $hiddenEl.hide();
          packeryGalleryList.layout();
          locoScroll.update();
          // $('html,body').animate({
          //     scrollTop: document.querySelector('.gallery-list--inner').offset().top - $('.navbar').height() * 2
          // }, 300);

          locoScroll.scrollTo(container.querySelector('.gallery-list--inner'), {
            offset: - $('.navbar').height() * 2
          })
        }, 400); //Same time as animation
        
        $moreBtn.removeClass('is-hidden');
        packeryGalleryList.layout();  
      });
    }

    // console.log(Spotlight)
    // $(".gallery-list").lightGallery({
    //   selector: ".gallery-list--item",
    //   download: false,
    //   counter: false,
    //   getCaptionFromTitleOrAlt: false,
    //   videoMaxWidth: "80%",
    //   youtubePlayerParams: {
    //     modestbranding: 1,
    //     showinfo: 0,
    //     rel: 0,
    //     controls: 0,
    //   },
    //   vimeoPlayerParams: {
    //     byline: 0,
    //     portrait: 0,
    //     dnt: true
    //   }
    // });
    let gallery = container.querySelector(".gallery-list");
    lightGallery(gallery, {
      plugins: [lgZoom, lgVideo],
      licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
      selector: ".gallery-list--item",
      counter: false,
      download: false,
      getCaptionFromTitleOrAlt: false,
      vimeoPlayerParams: {
        byline : 0,
        portrait : 0,
        dnt: true
      },
      youTubePlayerParams: {
        modestbranding : 1,
        showinfo : 0,
        controls : 0,
        rel: 0
      }
    });  

  }
  
  // Company Story
  if ($('.company-story').exists()) {
    let packeryCompanyStoryList = null;

    let elem = container.querySelector('.company-story--inner');
    packeryCompanyStoryList = new Packery( elem, {
      // options
      itemSelector: '.company-story--item',
      percentPosition: true,
    });

    imagesLoaded( elem ).on( 'progress', function() {
      packeryCompanyStoryList.layout();
      locoScroll.update();
    });
  }

  // add this code
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add("flickity-resize");
  };
  Flickity.createMethods.push("_createResizeClass");

  var FlickityResize = Flickity.prototype.resize;
  if ($(".flickity-resize").exists()) {
    Flickity.prototype.resize = function () {
      var $this = this;
      $(".flickity-resize").each(function () {
        $this.element.classList.remove("flickity-resize");
        FlickityResize.call($this);
        $this.element.classList.add("flickity-resize");
      });
    };
  }

  // Accordion
  if ($(".accordion-list").exists()) {
    $(".accordion-list").each(function(){
      var $list = $(this);
      var elms = $list.find(".accordion-item");
      $(elms).each(function(){
        var el = $(this);

        if($(this).hasClass('open')){
          $(this).find(".accordion-content").slideDown(300, function(){
            locoScroll.update();
            
            jobControl(el);
          });
        }
      });      

      elms.find('.accordion-title').on('click', function(){
        var $this = $(this).parent();

        if($list.hasClass('close-all')){
          if($this.hasClass('open')){
            $this.find(".accordion-content").stop().slideUp(300, function(){
              locoScroll.update();
              $this.removeClass('open');
            });
          } else {
            elms.find(".accordion-content").stop().slideUp(300);
            elms.removeClass('open');

            $this.find(".accordion-content").stop().slideDown(300, function(){
              locoScroll.update();

              jobControl($this);
            });

            $this.toggleClass("open");
          }

        } else {
          $this.find(".accordion-content").stop().slideToggle(300, function(){
            locoScroll.update();
          });

          $this.toggleClass("open");
        }
      })

    });
  }

  // Colors modal
  if ($(".colors-list-item[data-image]").exists()) {
    $(".colors-list-item[data-image]").click(function (e) {
      e.preventDefault();
      ColorsModal(this);
    });
  }

  // Contacts Form
  if ($("#contactForm").exists()) {
    var contactForm = $("#contactForm");
    contactForm.thomForm({
      errorMsg: contactForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          contactForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        contactForm.find(".thom-form__error").fadeIn(300, function(){
          locoScroll.scrollTo(container.querySelector('#contactForm'), {
            offset: - $('.navbar').height() * 2
          })
          locoScroll.update()

          // $('html,body').animate({
          //   scrollTop: document.querySelector('#contactForm').offset().top - $('.navbar').height() * 2
          // }, 300);
        });

        // $('html,body').animate({
        //   scrollTop: contactForm.offset().top - $('.navbar').height()
        // }, 600);
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // Contacts Map
  if ($(".contact-map").exists() && $(".contact-map #map").exists()) {

    loader.load().then(async (google) => {
      myGoogleMaps = new google.maps.Map(container.querySelector("#map"), {
        center: { lat: params.latitude, lng: params.longitude },
        zoom: 8,
        maxZoom: 18,
        streetViewControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
      });

      var image = {
        url: "/img/marker.png",
        size: new google.maps.Size(130, 110),
      };
      var myMarker = new google.maps.Marker({
        position: { lat: params.latitude, lng: params.longitude },
        map: myGoogleMaps,
        icon: image,
      });
      var bounds = new google.maps.LatLngBounds();
      bounds.extend(myMarker.getPosition());
      myGoogleMaps.fitBounds(bounds);
    })
    .catch((e) => {
      // do something
    });
  }

  // Newsletter Form
  if ($("#newsletterForm").exists()) {
    var newsletterForm = $("#newsletterForm");
    newsletterForm.thomForm({
      errorMsg: newsletterForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          newsletterForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        newsletterForm.find(".thom-form__error").fadeIn(300);

        $('html,body').animate({
          scrollTop: newsletterForm.offset().top - $('.navbar').height()
        }, 600);
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // Login Form
  if ($("#loginForm").exists()) {
    var loginForm = $("#loginForm");
    loginForm.thomForm({
      errorMsg: loginForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          loginForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        loginForm.find(".thom-form__error").fadeIn(300, function(){
          locoScroll.update()
        });

        // $('html,body').animate({
        //   scrollTop: loginForm.offset().top - $('.navbar').height() * 2
        // }, 600);

        locoScroll.scrollTo(container.querySelector("#loginForm"), {
          offset: - $('.navbar').height() * 2
        })
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // Login Form
  if ($("#registrationForm").exists()) {
    var registrationForm = $("#registrationForm");
    registrationForm.thomForm({
      errorMsg: registrationForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          registrationForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        registrationForm.find(".thom-form__error").fadeIn(300, function(){
          locoScroll.update()
        });

        // $('html,body').animate({
        //   scrollTop: loginForm.offset().top - $('.navbar').height() * 2
        // }, 300);

        locoScroll.scrollTo(container.querySelector("#registrationForm"), {
          offset: - $('.navbar').height() * 2
        })
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // Job Form
  if ($("#formJobOpportunities").exists()) {
    var jobForm = $("#formJobOpportunities");
    jobForm.thomForm({
      errorMsg: jobForm.find(".thom-form__error"),
      errorCallback: function(myTitle, myMessage) {
        if (myTitle) {
          myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          jobForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        jobForm.find(".thom-form__error").fadeIn(300, function(){
          locoScroll.scrollTo(container.querySelector('#contactForm'), {
            offset: - $('.navbar').height() * 2
          })
          locoScroll.update()
        });
      },
      myCallback: function(myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      }
    });
  }

  // News
  // News list
  if ($('.thom-search--results .news-list').exists()) {
    // console.log('news')
    var packeryNewsList;

    let newsPackery = function(){
      let elem = container.querySelector('.news-list--inner');
      let newsPackeryOptions = {
        itemSelector: '.news-list--item',
        percentPosition: true,
        transitionDuration: 0
      };
      packeryNewsList = new Packery( elem, newsPackeryOptions);
      imagesLoaded( elem ).on( 'progress', function(item) {
        packeryNewsList.layout();
        locoScroll.update();
      });
    }

    newsPackery();
    
    // News list infinitescroll / pagination / filters
    if ($(".thom-search--filter-block").exists() && (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {
      let infinitContainerScrollTrigger = null;
      var nextURL;

      let updateNextURL = (doc) => {
        nextURL = $(doc).find(".pagination__next").attr('href');
      }
      updateNextURL(container);

      let updateInfiniteScroll = () => {
        let infinitContainer = container.querySelector('.infinite-content');
        infinitContainerScrollTrigger = ScrollTrigger.create({
          trigger: infinitContainer,
          scroller: ".smooth-scroll",
          end: 'bottom bottom',
          // markers: true,
          onLeave: self => {       
            if(nextURL != undefined && nextURL != '' && nextURL != null){
              $('.page-load-status').show().addClass('active');
              $.ajax({
                url: nextURL,
                success: function (newHTML, textStatus, jqXHR) {
                  var myHtml = $(newHTML);
                  var items = myHtml.find('.news-list--item');
                  var nextPag = myHtml.find('.pagination__next').attr('href');
                  $('.pagination__next').attr('href', nextPag);
                  updateNextURL(container);
                  for(var i = 0; i < items.length; i++){
                    var item = items[i];

                    infinitContainer.append(item)

                    if (matchMedia("screen and (min-width: 768px)").matches) {
                      packeryNewsList.appended(item);
                      setTimeout(function(){
                        packeryNewsList.layout();
                        packeryNewsList.on( 'layoutComplete', function( items ) {
                          self.refresh();
                          locoScroll.update();
                        })
                      }, 50);

                    }
        
                    if ($(item).find(".lazy").length > 0) {
                      myLazyLoad.update();
                    }

                    if((i + 1) == (items.length)){
                      $('.page-load-status').hide().removeClass('active');
                      
                      setTimeout(function () {
                        self.refresh();
                        locoScroll.update();
                      }, 50);
                    }

                  }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                  // Handle AJAX errors
                  $(".page-load-status").hide().removeClass('active');
                },
                complete: function () {
                },
                // More AJAX customization goes here.
              });
            }
          }
        });
      }

      if ($('.pagination__next').length ) {
        updateInfiniteScroll();
      }
      
      ThomAdvancedSearch = $('.thom-search').thomsearch({
        filterClass: '.thom-search--filter-btn',
        filtersContent: '.thom-search--filter-block',
        itemsPerPage: THOM_searchItemsPerPage,
        resultsCallback: function (data) {
          var infScroll = $('.thom-search--results .news-list .infinite-content').data('infiniteScroll');
          if(infScroll) {
            infScroll.destroy();
          }
          if(infinitContainerScrollTrigger != null){
            infinitContainerScrollTrigger.kill();
          }
          $('.thom-search--results').html(data);  
          var items = $('.thom-search--results').find('.news-list--item');

          packeryNewsList.destroy();

          for(var i = 0; i < items.length; i++){
            var item = items[i];

            if ($(item).find(".lazy").length > 0) {
              myLazyLoad.update();
            }

            if((i + 1) == (items.length)){
              ThomAdvancedSearch.thomsearch('loadingHide');
              newsPackery();
              // self.refresh();
              locoScroll.update();
            }
          }

          updateNextURL(container);
          if ( $('.pagination__next').length ) {
            updateInfiniteScroll();
          }
        },
        urlContent: THOM_searchUrlContent
      });
    }

  }
  
  // Press
  // Press list infinitescroll / pagination / filters
  if ($(".thom-search--filter-block").exists() && $('.thom-search--results .press-list').exists() &&
  (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {
    let nextURL;
    let infinitContainerScrollTrigger = null;
    let updateNextURL = (doc) => {
      nextURL = $(doc).find(".pagination__next").attr('href');
    }
    // get initial nextURL
    updateNextURL(container);

    let updateInfiniteScroll = () => {
      let infinitContainer = document.querySelector('.infinite-content');
      infinitContainerScrollTrigger = ScrollTrigger.create({
        trigger: infinitContainer,
        scroller: ".smooth-scroll",
        end: 'bottom bottom',
        // markers: true,
        onLeave: self => {
          if(nextURL != undefined && nextURL != '' && nextURL != null){
            $('.page-load-status').show().addClass('active');
            $.ajax({
              url: nextURL,
              success: function (newHTML, textStatus, jqXHR) {
                // console.log(newHTML.querySelector('.thom-search--pagination'))
                var myHtml = $(newHTML);
                var items = myHtml.find('.item');
                var nextPag = myHtml.find('.pagination__next').attr('href');
                $('.pagination__next').attr('href', nextPag);
                updateNextURL(document);
                
                for(var i = 0; i < items.length; i++){
                  var item = items[i];

                  infinitContainer.append(item)

                  if ($(item).find(".lazy").length > 0) {
                    myLazyLoad.update();
                  }

                  if((i + 1) == (items.length)){
                    $('.page-load-status').hide().removeClass('active');
                    
                    setTimeout(function () {
                      self.refresh();
                      locoScroll.update();
                    }, 50);
                  }
                }
              },
              error: function (jqXHR, textStatus, errorThrown) {
                // Handle AJAX errors
                $(".page-load-status").hide().removeClass('active');
                $(".pagination__next").hide();
              },
              complete: function () {
              },
              // More AJAX customization goes here.
            });
          }
          // self.kill()
        }
      });
    }

    if($('.pagination__next').length) {
      updateInfiniteScroll();
    }
    
    ThomAdvancedSearch = $('.thom-search').thomsearch({
      filterClass: '.thom-search--filter-btn',
      filtersContent: '.thom-search--filter-block',
      itemsPerPage: THOM_searchItemsPerPage,
      resultsCallback: function (data) {
        var infScroll = $('.thom-search--results .press-list .infinite-content').data('infiniteScroll');
        if(infScroll) {
          infScroll.destroy();
        }
        if(infinitContainerScrollTrigger != null){
          infinitContainerScrollTrigger.kill();
        }
        $('.thom-search--results').html(data); 
        var items = $('.thom-search--results .item');

        for(var i = 0; i < items.length; i++){
          var item = items[i];

          if ($(item).find(".lazy").length > 0) {
            myLazyLoad.update();
          }

          if((i + 1) == (items.length)){
            ThomAdvancedSearch.thomsearch('loadingHide');
            setTimeout(function () {
              locoScroll.update();
            }, 50);
          }
        }

        updateNextURL(container);
        
        if($('.pagination__next').length) {
          updateInfiniteScroll();
        }
      },
      urlContent: THOM_searchUrlContent
    });
  }

  // Download list
  if ($('.download-list').exists()) {
    
    let packeryDownloadList;

    var downloadPackery = function(){
      var elem = container.querySelector('.download-list--inner');

      var downloadPackeryOptions = {
        itemSelector: '.download-list--item',
        percentPosition: true,
        transitionDuration: 0
      };

      packeryDownloadList = new Packery( elem, downloadPackeryOptions);

      imagesLoaded(elem).on( 'progress', function(instance, image) {
        packeryDownloadList.layout();
      });
    }
    if (matchMedia("screen and (min-width: 768px)").matches) {
      downloadPackery();
    }

    // Download list infinitescroll / pagination
    if ($('.download-list .infinite-content').exists()) {
      let nextURL;
      
      let updateNextURL = (doc) => {
        nextURL = $(doc).find(".pagination__next:last-child").attr('href');
      }
      updateNextURL(container);

      let updateInfiniteScroll = () => {
        let infinitContainer = container.querySelector('.infinite-content');
        ScrollTrigger.create({
          trigger: infinitContainer,
          scroller: ".smooth-scroll",
          end: 'bottom bottom',
          // markers: true,
          onLeave: self => {            
            if(nextURL != undefined && nextURL != '' && nextURL != null){
              $('.page-load-status').show().addClass('active');
              $.ajax({
                url: nextURL,
                success: function (newHTML, textStatus, jqXHR) {
                  // console.log(newHTML.querySelector('.thom-search--pagination'))
                  var myHtml = $(newHTML);
                  var items = myHtml.find('.download-list--item');
                  var nextPag = myHtml.find('.pagination__next').attr('href');
                  $('.pagination__next').attr('href', nextPag);
                  updateNextURL(container);
                  // $(myHtml).appendTo("body").modal();
                  for(var i = 0; i < items.length; i++){
                      var item = items[i];

                      infinitContainer.append(item)

                      if (matchMedia("screen and (min-width: 768px)").matches) {
                        packeryDownloadList.appended(item);
                        setTimeout(function(){
                          packeryDownloadList.layout();
                          packeryDownloadList.on( 'layoutComplete', function( items ) {
                            self.refresh();
                            locoScroll.update();
                          })
                        }, 50);
                      }
          
                      if ($(item).find(".lazy").length > 0) {
                        myLazyLoad.update();
                      }

                      if((i + 1) == (items.length)){
                        $('.page-load-status').hide().removeClass('active');
                        
                        setTimeout(function () {
                          self.refresh();
                          locoScroll.update();
                        }, 50);
                      }
                  }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                  // Handle AJAX errors
                  $(".page-load-status").hide().removeClass('active');
                },
                complete: function () {
                },
                // More AJAX customization goes here.
              });
            }
            // self.kill()
          }
        });
      }

      if ($('.pagination__next').length) {
        updateInfiniteScroll();
      }
    }
  }

  // Projects
  // Projects list infinitescroll / pagination
  if ($('.projects-list').exists() &&
  (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {
    // console.log('project')
    let infinitContainerScrollTrigger = null;
    let nextURL;

    let updateNextURL = (doc) => {
      nextURL = $(doc).find(".pagination__next:last-child").attr('href');
    }
    updateNextURL(container);

    let updateInfiniteScroll = () => {
      let infinitContainer = container.querySelector('.infinite-content');
      infinitContainerScrollTrigger = ScrollTrigger.create({
        trigger: infinitContainer,
        scroller: ".smooth-scroll",
        end: "bottom bottom",
        // markers: true,
        onLeave: (self) => {
          if (nextURL != undefined && nextURL != "" && nextURL != null) {
            $(".page-load-status").show().addClass('active');
            $.ajax({
              url: nextURL,
              success: function (newHTML, textStatus, jqXHR) {
                // console.log(newHTML.querySelector('.thom-search--pagination'))
                var myHtml = $(newHTML);
                var items = myHtml.find(".projects-list--item");
                var nextPag = myHtml.find(".pagination__next").attr("href");
                $(".pagination__next").attr("href", nextPag);
                updateNextURL(container);
                // $(myHtml).appendTo("body").modal();
                for (var i = 0; i < items.length; i++) {
                  var item = items[i];

                  infinitContainer.append(item);

                  if ($(item).find(".lazy").length > 0) {
                    myLazyLoad.update();
                  }

                  if (i + 1 == items.length) {
                    $(".page-load-status").hide().removeClass('active');

                    setTimeout(function () {
                      self.refresh();
                      locoScroll.update();
                    }, 50);
                  }
                }
              },
              error: function (jqXHR, textStatus, errorThrown) {
                // Handle AJAX errors
                $(".page-load-status").hide();
                $(".pagination__next").hide();
              },
              complete: function () {},
              // More AJAX customization goes here.
            });
          }
        },
      });
    }

    if ( $('.pagination__next').length ) {
      updateInfiniteScroll();
    }
  }

   // Store list
  // Store list infinitescroll / pagination / filters
  if ($(".thom-search--filter-block").exists() && $('.stores-list').exists() &&
  (typeof ThomAdvancedSearch === 'undefined' || ThomAdvancedSearch === null)) {
    // console.log('store')
    var subDropdown = $('.thom-search').find('.sub-dropdown');
    
    ThomAdvancedSearch = $('.thom-search').thomsearch({
      filterClass: '.thom-search--filter-btn',
      filtersContent: '.thom-search--filter-block',
      resultsCallback: function (data) {

        subDropdown.html('');
        
        $('.thom-search--results').html(data);
        
        var items = $('.thom-search--results').find('.stores-list--item');
        for(var i = 0; i < items.length; i++){
          if((i + 1) == (items.length)){
            ThomAdvancedSearch.thomsearch('loadingHide');
            locoScroll.update();
          }
        }

        var dropdown = $('.thom-search--results').find('.thom-dropdown');
        subDropdown.append(dropdown);
        var newDropdown = subDropdown.find('.thom-dropdown');
        
        thomDropdown('newdropdowns');

        newDropdown.find('.thom-dropdown--options-item').on('click', function(){
          newDropdown.find('.thom-dropdown--options-item').removeClass('is-selected');
          $(this).addClass('is-selected');
          var region = $(this).data('region');
          for(var i = 0; i < items.length; i++){
            var elRegion = $(items[i]).data('region');
            if(elRegion == region | region == 0) {
              $(items[i]).css({'opacity': 1, 'transform': 'translate(0px,0px)'}).show()
            } else {
              $(items[i]).attr('style', '').hide();
            }
          }
        });        
      },
      urlContent: THOM_searchUrlContent
    });
  }

  // Stores
  if ($(".stores").exists() && !$(".agents").exists()) {
    loader.load().then(async (google) => {
        // Thom Map INIT
        myGoogleMaps = $("#map").thomMap({
          autocomplete: 1,
          sendJson: true,
          content: $(".stores"),
          fitbounds: true,
          getMarkerIcon: function (item) {
            return myThomMapMarkerIcon(item, true);
          },
          params: params,
          // searchBtn: $(".stores-search-btn"),
          searchCallback: function(){
            locoScroll.update()
            // $('.stores-search-preview').hide();
            // $('.stores-results').show();
          },
          styled: new google.maps.StyledMapType(
            [
              {
                  "featureType": "administrative",
                  "elementType": "labels.text.fill",
                  "stylers": [
                      {
                          "color": "#444444"
                      }
                  ]
              },
              {
                  "featureType": "landscape",
                  "elementType": "all",
                  "stylers": [
                      {
                          "color": "#f2f2f2"
                      }
                  ]
              },
              {
                  "featureType": "poi",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "poi",
                  "elementType": "labels.text",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "road",
                  "elementType": "all",
                  "stylers": [
                      {
                          "saturation": -100
                      },
                      {
                          "lightness": 45
                      }
                  ]
              },
              {
                  "featureType": "road.highway",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "simplified"
                      }
                  ]
              },
              {
                  "featureType": "road.arterial",
                  "elementType": "labels.icon",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "transit",
                  "elementType": "all",
                  "stylers": [
                      {
                          "visibility": "off"
                      }
                  ]
              },
              {
                  "featureType": "water",
                  "elementType": "all",
                  "stylers": [
                      {
                          "color": "#dbdbdb"
                      },
                      {
                          "visibility": "on"
                      }
                  ]
              }
            ],
            {
              name: 'Styled Map'
            }
          ),
          url: thomMap_url,
          zoomControl: true,
          zoomControlPosition: google.maps.ControlPosition.RIGHT_TOP,
          zoomToMarker: true,
        });
      })
      .catch((e) => {
        // do something
      });
  }

  // Stores Agents
  if ($(".agents").exists()) {
    loader.load().then(async (google) => {
        // Thom Map INIT
        myGoogleMaps = $("#map").thomMap({
          autocomplete: 1,
          autocompleteOnly: true,
          sendJson: true,
          content: $(".stores"),
          params: params,
          url: thomMap_url,
          searchCallback: function(){
            // console.log('searchCallback')
            var elems = $('.map-item');
            var count = elems.length;

            if (count != 0) {
              $('.map-msg').fadeOut(300, function () {
                $(this).hide(0, function(){
                  locoScroll.update();
                });
              });
            } else {
              $('.map-msg').fadeIn(300, function () {
                locoScroll.update()
              });
            }
          }
        });                
      })
      .catch((e) => {
        // do something
      });
  }

  // scroll to div
  if ($(".scrollto").exists()) {
    $(".scrollto").on('click', function (e) {
      e.preventDefault();
      var link = $(this).data("link");
      // $("html,body").animate(
      //   {
      //     scrollTop: $("#" + link).offset().top - $(".navbar").height() * 2,
      //   },
      //   600
      // );
      locoScroll.scrollTo(document.querySelector("#" + link), {
        offset: - $('.navbar').height() * 2
      })
    });
  }

  if ($('.gallery--list').exists()) {
    let galleryList = container.querySelector(".gallery--list");
    lightGallery(galleryList, {
      plugins: [lgZoom, lgVideo],
      licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
      selector: ".item",
      counter: false,
      download: false,
      getCaptionFromTitleOrAlt: false,
      vimeoPlayerParams: {
        byline : 0,
        portrait : 0,
        dnt: true
      },
      youTubePlayerParams: {
        modestbranding : 1,
        showinfo : 0,
        controls : 0,
        rel: 0
      }
    });

    if($('.gallery--list .item').length > 2 && $('.gallery--list').find('.show-more').length > 0){
      let $this = $('.gallery--list');
      let $hiddenEl = $this.find('.hidden');
      let $moreBtn = $this.find('.show-more');
      let $lessBtn = $this.find('.show-less');

      $moreBtn.on('click', function(){
        $moreBtn.addClass('is-hidden');

        $hiddenEl.show();
        $hiddenEl.removeClass('out').addClass('active');

        $lessBtn.removeClass('is-hidden');

        locoScroll.update();
      });

      $lessBtn.on('click', function(){
        $lessBtn.addClass('is-hidden');
        
        $hiddenEl.removeClass('active').addClass('out');

        setTimeout(function(){
          $hiddenEl.hide();

          setTimeout(function(){
            locoScroll.scrollTo(container.querySelector(".gallery--list"), {
              offset: - $('.navbar').height() * 2
            })
          }, 1);


          locoScroll.update();

        }, 400); //Same time as animation
        
        $moreBtn.removeClass('is-hidden');
      });
    }

  }

  // Open gallery from photogallery button
  if ($(".btn-gallery").exists()) {
    $(".btn-gallery").each(function () {
      var $this = $(this);
      var $lggallery = $("#" + $this.data("gallery"));

      lightGallery($lggallery, {
        plugins: [lgZoom, lgVideo],
        licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
        selector: "img",
        counter: false,
        download: false,
        getCaptionFromTitleOrAlt: false,
        vimeoPlayerParams: {
          byline : 0,
          portrait : 0,
          dnt: true
        },
        youTubePlayerParams: {
          modestbranding : 1,
          showinfo : 0,
          controls : 0,
          rel: 0
        }
      });

      $this.click(function (e) {
        e.preventDefault();
        $lggallery.find("img").first().trigger("click");
      });
    });
  }

  let btnVideo = container.querySelector(".btn-video");
  lightGallery(btnVideo, {
    plugins: [lgZoom, lgVideo],
    licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
    selector: "this",
    counter: false,
    download: false,
    getCaptionFromTitleOrAlt: false,
    vimeoPlayerParams: {
      byline : 0,
      portrait : 0,
      dnt: true
    },
    youTubePlayerParams: {
      modestbranding : 1,
      showinfo : 0,
      controls : 0,
      rel: 0
    }
  });

  // Ajax modal
  $('a[rel="ajax:modal"]').unbind('click');
  $('a[rel="ajax:modal"]').click(function (event) {
    event.preventDefault();

    // console.log(this)

    this.blur();
    $.ajax({
      url: $(this).attr("href"),
      success: function (newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml).appendTo("body").modal();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        // Handle AJAX errors
      },
      complete: function () {},
      // More AJAX customization goes here.
    });

    return false;
  });

  // Inline modal 
  if ($('.inline-modal[data-modal]').exists()){
    $('.inline-modal[data-modal]').click(function (event) {  
      
      event.preventDefault();
      this.blur();

      var filesmodal = $($(this).data("modal"));

      filesmodal.modal();
    });
  }
  
  // form file
  if ($('.thom-form-selectfile').exists()) {
    $('.thom-form-selectfile').change(function (e) {
      e.preventDefault();
      var filename = $(this).find('.thom-form-selectfile__input_file').val().replace('C:\\fakepath\\', '')
      // var ext = filename.split('.').pop().toLowerCase()
      $(this).find('.thom-form-selectfile__label span').html(filename)
    })
  }

  // video media / Scrolltrigger
  if($('.media-content').exists()){
    $('.media-content').each(function(){
      var $this = $(this).find('.media');
      if($this.find(".video").length > 0){
        let videoElement = $this.find(".video")[0];
        let videoSrc = $this.find(".video").data('src');

        videoElement.src = videoSrc;
        videoElement.load();

        if($this.find('.video--controls').length > 0){
          var controls = $this.find('.video--controls');
          var restart = controls.find('.skip-start');
          var volume = controls.find('.volume');
          var state = controls.find('.state');
          var progress = controls.find('.progress-bar');
  
          if(window.matchMedia("(min-width: 920px)").matches){
            setTimeout(function(){
              controls.fadeOut();
            }, 2000);
  
            $this.hover(function(){
              controls.fadeIn();
            }, function(){
              controls.fadeOut();
            })
          }
  
          restart.on('click', function(){
            videoElement.pause();
            videoElement.currentTime = '0';
            videoElement.play();
            state.removeClass('stop');
          });
  
          volume.on('click', function(){
            $(this).toggleClass('on');
            if($(this).hasClass('on')){
              videoElement.muted = false;
            } else {
              videoElement.muted = true;
            }
          });
  
          state.on('click', function(){
            $(this).toggleClass('stop');
            if($(this).hasClass('stop')){
              videoElement.pause();
            } else {
              videoElement.play();
            }
          });
        }
        
        let itemOnEnter = () => {
          $this.addClass('is-playing');
          videoElement.play();
        }
        let itemOnLeave = () => {
          $this.removeClass('is-playing');
          videoElement.pause();
        }

        var mediaTl = gsap.timeline({
          scrollTrigger: {
            trigger: $this[0],
            start: "top 80%",
            end: "bottom 10%",
            scroller: ".smooth-scroll",
            // markers: true,
            onEnter: itemOnEnter,
            onEnterBack: itemOnEnter,
            onLeave: itemOnLeave,
            onLeaveBack: itemOnLeave,
          }
        });
      } else if($this.find(".video-vimeo").length > 0){
        let videoElement = $this.find(".video-vimeo");
        let vimeoId = videoElement.data('src');

        var playerOptions = {
          id: vimeoId,
          loop: true,
          controls: false,
          autoplay: false
        }

        let player = new Player(videoElement, playerOptions);

        if($this.find('.video--controls').length > 0){
          let controls = $this.find('.video--controls');
          let restart = controls.find('.skip-start');
          let volume = controls.find('.volume');
          let state = controls.find('.state');
          // let progress = controls.find('.progress-bar');
  
          if(window.matchMedia("(min-width: 920px)").matches){
            setTimeout(function(){
              controls.fadeOut();
            }, 2000);
  
            $this.hover(function(){
              controls.fadeIn();
            }, function(){
              controls.fadeOut();
            })
          }

          volume.addClass('on');
  
          restart.on('click', function(){
            player.pause();
            player.setCurrentTime(0).then(function(seconds) {
              player.play();
              state.removeClass('stop');
            });
          });

          volume.on('click', function(){
            $(this).toggleClass('on');
            if($(this).hasClass('on')){
              player.setMuted(false);
            } else {
              player.setMuted(true);
            }
          });
  
          state.on('click', function(){
            $(this).toggleClass('stop');
            if($(this).hasClass('stop')){
              player.pause();
            } else {
              player.play();
            }
          });
        }

        let itemOnEnter = () => {
          $this.addClass('is-playing');
          player.play();
        }
        let itemOnLeave = () => {
          $this.removeClass('is-playing');
          player.pause();
        }

        var mediaTl = gsap.timeline({
          scrollTrigger: {
            trigger: $this[0],
            start: "top 80%",
            end: "bottom 10%",
            scroller: ".smooth-scroll",
            // markers: true,
            onEnter: itemOnEnter,
            onEnterBack: itemOnEnter,
            onLeave: itemOnLeave,
            onLeaveBack: itemOnLeave,
          }
        });
      }
    })
  }
  
  // update analytics
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }

  if($('.scrollAnim').exists()){
    scrollAnimation(locoScroll);
  }

  if($('.homeBlockLink').exists()){
    if ( matchMedia('screen and (max-width: 768px)').matches && detectTouchscreen()) {

      $('.homeBlockLink .link').each(function(){
        $(this).attr('data-barba-prevent', 'self');
      })
      $('.homeBlockLink .link').on('click', function(e){
        e.preventDefault();
        var link = $(this).attr('href');
        $('.homeBlockLink .link').not(this).removeClass('link-active')
        if($(this).hasClass('link-active')){
          barba.go(link);
        } else {
          $(this).addClass('link-active')
        }
      })

      $(document).on("click", function(event){
        if(!$(event.target).closest(".link").length){
          $('.homeBlockLink .link').removeClass('link-active');
        }
      });
    }
  }

  if($('.main').attr('data-barba-namespace') === "newsletter"){
    setCookie('displayedPopupNewsletter', 'yes', 365);
  }

  // Popup Newsletter visibile dopo 3 minuti
  // se il cookie "displayedPopupNewsletter" ha valore diverso da "yes"
  if (getCookie('displayedPopupNewsletter') != "yes" && $('#NewsletterModal').exists()) {

    $('#NewsletterModal').modal({
      clickClose: false,
      fadeDuration: 400
    });

    $('#NewsletterModal').on($.modal.CLOSE, function (event, modal) {
      setCookie('displayedPopupNewsletter', 'yes', 365);
    });
  }

  myLazyLoad.update();

  // update recaptcha
  if($('.thom-form__recaptcha').exists()){
    if (!$('.thom-form__recaptcha').is('[data-widget-id]')) {
      recaptchaLoad();
    }
  }
}

// barba.use(barbaCss);

if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}
let scrollPosY = [0];

barba.hooks.before(() => {
  // console.log('before')
  locoScroll.stop();
  $('.c-scrollbar').show();
});
barba.hooks.after(() => {
  // console.log('locoScroll start')
  locoScroll.start();
});
/* CONTENT 
LOADED */

barba.init({
  timeout: 9000,
  prefetchIgnore: true,
  transitions: [{
      async leave(data) {
        //console.log('BARBA LEAVE');
        await pageTransitionIn(data);
        // data.current.container.remove();
      },
      async enter(data) {
        //console.log('BARBA ENTER');
        await pageTransitionOut(data.next.container, data, data.next.namespace);
      },
      async once(data) {
        //console.log('BARBA ONCE')
        await pageTransitionIn(data);
        await pageTransitionOut(document, data, data.next.namespace);
      },
      async afterLeave(data) {
        //console.log('BARBA AFTER LEAVE')
        // Kill scrolltrigger instande
        let tlChildren = gsap.globalTimeline.getChildren();
        if(tlChildren){
            tlChildren.forEach(child => {
                child.kill();
                child = null;
            });
        }

        // Inject scripts on page
        const js = data.next.container.querySelectorAll("script");
        [].forEach.call(js, function (script) {
          // console.log(script);
          window.eval(script.innerHTML);
        });
      }
    },
  ],
});

function pageTransitionIn(data) {
  //console.log('page Transition In');

  if(data && data.trigger !== "back") {
    scrollPosY.push(barba.history.current.scroll.y);
  }
  
  // return $("#spinner").addClass("show")
  return gsap
        .timeline()
        .add('start') // Use a label to sync screen and content animation
        .to("#spinner", {
            // opacity: 1,
            onStart: () => {
              $("#spinner").addClass("show")
            }
        }, 'start')
}

function pageTransitionOut(container, data, namespace){
  //console.log('page Transition Out')
  if(data.trigger !== "back") {
    locoScroll.scrollTo("top", {
      offset: 0,
      smooth: false,
      disableLerp: true,
      duration: 0
    });
  } else {
    locoScroll.scrollTo("top", {
      offset: scrollPosY.pop(),
      smooth: false,
      disableLerp: true,
      duration: 0
    });
  }

  if(myGoogleMaps != null){
    myGoogleMaps = null;
  }

  $.modal.close();
  document.body.classList.remove("navbar--opened");

  $.ajax({
    url: data.next.url.href,
    success: function (newHTML, textStatus, jqXHR) {
      // Meta update
      var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
      ].join(",");
      $("head").find(headTags).remove();
      $(newHTML).find(headTags).appendTo("head");

      // Section placeholder Change
      var newplaceholdersection = $(newHTML)
        .find(".placeholder-section")
        .html();
      $(".navbar .placeholder-section").html(newplaceholdersection);

      // Menu Change
      var newmenu = $(newHTML).find(".navbar__collapse").html();
      $(".navbar .navbar__collapse").html(newmenu);

      // Menu Change
      var newmenuPreview = $(newHTML).find(".navbar__dx").html();
      $(".navbar__dx").html(newmenuPreview);

      return contentAnimation(container, data, namespace);
    },
    error: function (xhr, ajaxOptions, thrownError) {
      console.log(xhr.statusText)
      console.log(thrownError)
    },
    complete: function () {
      // spinner().hide();
    },
  });
}

function contentAnimation(container, data, namespace) {
  //console.log('content Animation');
  (async () => {
    if(document.body.classList.contains('navbar--opened')){
        document.body.classList.remove('navbar--opened');
    }
    // console.log('content Animation')

    var startMyApp = await myapp(container);
    var resetLocoscrollPos = await locoscrollResetPosition();
    var hideLoading = await loadingHide();
    return {
        startMyApp,
        resetLocoscrollPos,
        hideLoading
     };
  })()
}

function locoscrollResetPosition() {
  //console.log('reset Locoscroll Position');
  locoScroll.scrollTo(0, {
    offset: 0,
    smooth: false,
    disableLerp: true,
    duration: 0
  });
}

function loadingHide() {
  //console.log('loading Hide');
  locoScroll.update();
  
  //console.log(locoScroll)
  $("#spinner").removeClass("default").removeClass("show");
  // locoScroll.start();
}
